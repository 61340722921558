<template>
  <div class="zone">
    <TJDetailTitle :titleData="'维修统计_医院'" />
    <functionBox 
      totalText=""
      :titleData="{permission_flag:'Maintain',nowID:2}"
      :searhLoading="loading"
      @searchBtn="searchBtn"
      >
      <template>
        <li>
          <ProvinceSelect @selectedProvince="handleSelectedProvince"></ProvinceSelect>
        </li>
        <li>
          <el-select
          v-model="hospital_id"
          placeholder="请选择医院"
          size="small"
          filterable
          clearable
          :remote="province_id==''"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in hospitalList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        </li>
        <li>
          <timePicker @setTime="setTime" radioParent="年"></timePicker>
        </li>
      </template>
    </functionBox> 
    <!-- <div class="indent">
      <div class="indentone">
        <div class="left cursorpointer" @click="goSales">省份</div>
        <div class="line"></div>
        <div class="spital cursorpointer">医院</div>
        <div class="line"></div>
        <div class="Volume cursorpointer" @click="goVolume">仪器</div>
      </div>
      <div class="below">
        <div class="city">
          <el-select
            v-model="province_id"
            filterable
            clearable
            placeholder="请选择省份"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="city1">
          <el-select
            v-model="hospital_id"
            placeholder="请选择医院"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="calendarone">
          <div class="title">时间：</div>
          <el-radio-group v-model="radio" size="small">
            <el-radio-button label="年"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="日"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="calendar">
          <div v-if="radio === '年'" class="el_calendar_year">
            <el-date-picker
              v-model="start_stats_year"
              type="year"
              :placeholder="startPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
            ～
            <el-date-picker
              v-model="end_stats_year"
              type="year"
              :placeholder="endPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
          </div>
          <el-date-picker
            v-else
            ref="datedate"
            size="small"
            v-model="dateMonthValue"
            :format="format"
            :value-format="formatValue"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            class="Calendar"
          />
        </div>

        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn">
            搜索
          </el-button>
        </div>
      </div>
    </div> -->
    <div class="sheet">
      <el-table
        :data="tableData"
        stripe
        height="700"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          label="序号"
          height="30"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="hospital_name"
          label="医院"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ottoman_1000"
          label="OTTOMAN_1000"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ottoman_2020"
          label="OTTOMAN_2020"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="maya_500"
          label="MAYA-500"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="maya_300"
          label="MAYA-300"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mini"
          label="mini+"
          align="center"
        ></el-table-column>
        <el-table-column prop="count_sum" label="总计" align="center"></el-table-column>
        <!-- <el-table-column
          prop=" "
          label="百分比"
          align="center"
        ></el-table-column> -->
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import { hospitals_list } from '@/api/search.js'
import functionBox from './components/functionBox.vue'
import timePicker from '@/components/timePicker'
import linkageMixins from './components/linkageMixins.js'
import ProvinceSelect from "@/components/ProvinceSelect.vue";

export default {
  components: { TJDetailTitle, functionBox,timePicker,ProvinceSelect },
  mixins: [linkageMixins],
  name: 'GoAgency',
  data() {
    return {
      options: JSON.parse(localStorage.getItem('province')), //地区列表
      province_id: '', //地区选择值
      isProvince: false,
      radio: '年', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择

      hospital_id: '', //医院下拉框列表-值
      hospitalList: [], //医院下拉框列表

      page: 1,
      size: 10,
      total: 0,
      loading: false, //加载动画
      tableData: [],
    }
  },
  mounted() {
    this.hospital_stats_repairFun()
  },
  methods: {
    handleSelectedProvince(val){
      this.province_id = val
      this.changeProvince(val)
    },
    // 省份改变
    changeProvince(val) {
      this.hospitalList = this.provinceHospitalList.find(e=> this.province_id==e.province_id).hospital_list.map(e=> {
        return {
          value: e.hospital_id,
          label: e.hospital_full_name,
        }
      })
      this.hospital_id='';
    },
    // 医院远程搜索
    remoteMethod(e) {
      this.hospitalList = hospitals_list({ hospital_name: e })
    },

    // 省份维修统计
    hospital_stats_repairFun() {
      this.loading = true
      let param = {
        province_id: this.province_id, //省份标识
        hospital_id: this.hospital_id, //医院标识

        page: this.page,
        size: this.size,
        ...this.filterQuery(),
      }
      api.hospital_stats_repair(param).then(
        (res) => {
          if (res.code == 200) {
            this.tableData = res.data.data
            this.total = res.data.count
            this.loading = false
          }
        },
        (err) => {
          this.loading = false
        },
      ).catch(err=>{
        this.loading = false
      })
    },
    // 根据回调修改当前页的相关属性
    setTime(val) {
      this[val.name] = val.value
    },
  
    // 跳转页面携带参数处理
    filterQuery() {
      let param = {
        date_type: '', //日期类型:0:年,1,月,2,日
      }
      if (this.radio == '年') {
        param.start_stats_year = this.start_stats_year
        param.end_stats_year = this.end_stats_year
        param.date_type = 0
      } else if (this.radio == '月') {
        param.start_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 1
      } else if (this.radio == '日') {
        param.start_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 2
      }
      return param
    },
   
    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },

    // 查询
    searchBtn() {
      this.page = 1
      // if (
      //   this.radio == '年' &&
      //   (this.start_stats_year == '' ||
      //     this.end_stats_year == '' ||
      //     this.start_stats_year == null ||
      //     this.end_stats_year == null)
      // ) {
      //   this.$message.warning('请选择开始年份及结束年份')
      //   return
      // }
      this.hospital_stats_repairFun()
    },

    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.hospital_stats_repairFun()
    },

    //   省份
    goSales() {
      this.$router.push({ path: '/Maintain' })
    },
    //   仪器
    goVolume() {
      this.$router.push({ path: '/GoVolume' })
    },

    // 汇总计算
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' '
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  // width: px(1630);
  height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
}

.indentone {
  width: 100%;
  height: px(56);
  display: flex;
  border-bottom: 1px solid #d0d7e0;
  margin-bottom: px(15);
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.spital {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
  margin-right: px(20);
}
.Volume {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.Device {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}

.line {
  width: 1px;
  height: 13px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: px(16);
  margin-right: px(20);
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
  height: px(36);
  display: flex;
}
.calendar {
  margin-left: px(10);
}

.cityProvince {
  margin-left: px(10);
}
.city1 {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}
.calendarone {
  display: flex;
  margin-left: px(10);
}
.seachone {
  margin-left: px(10);
}
.sheet {
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
}
.series {
  display: flex;
  margin-left: -12px;
}
.series img {
  width: px(36);
  height: px(36);
}
.bd8 {
  width: 1px;
  height: 8px;
  border-radius: 0.5px 0.5px 0.5px 0.5px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: 10px;
}
.combination {
  font-size: px(16);
  color: #333333;
  margin-top: px(10);
}
::v-deep .el-table__footer-wrapper .cell {
  font-size: px(14);
  font-weight: bold;
  color: #006ed0ff;
}
</style>
